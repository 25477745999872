import { Configuration } from '@azure/msal-browser';

function isProd(): boolean {
  const { hostname } = window.location;
  const domains = [
    'feature-flags.adidas.com',
    'feature-flags.glass-tools.peu01.k8s.anteater.dub.aws.k8s.3stripes.net',
    'glass-feature-flags-ui.glass.peu01.k8s.anteater.dub.aws.k8s.3stripes.net',
  ];
  return domains.includes(hostname);
}

const MSAL_CLIENT_ID = isProd()
  ? '87832d13-a68a-4bb2-98aa-cb54ec5156ee'
  : '8314814d-3604-41f0-b8d8-d38dd8919eee';

export const msalConfig: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: '/',
  },
};

export const loginRequest = {
  scopes: [`${msalConfig.auth.clientId}/.default`],
};
